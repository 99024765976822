<template>
    <div>
         <b-row class="app-component-icon-edit">
                <b-dropdown variant="link"  no-caret right v-if="user_role < 3 || profile.can_user_edit">
                    <template #button-content>
                        <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                    </template>
                    <b-dropdown-item @click="change()">{{trans('change',175)}}</b-dropdown-item>
                </b-dropdown>
            </b-row>

            <b-row class="app-card-header">
                <b-col start>
                    <p class="col-list-label">{{trans('as-desired-start-date',217)}}</p>
                    <p class="col-list-label">{{trans('planning-last-start-date',209)}}</p>
                    <p class="col-list-label">{{trans('date-to',182)}}</p>
                    <p class="col-list-label">{{trans('planning-number-of-hours-per-week',209)}}</p>
                </b-col>
                <b-col>
                    <p class="col-form-value">{{profile.assignment_data.date_from ? profile.assignment_data.date_from : '-'}}</p>
                    <p class="col-form-value">{{profile.assignment_data.last_date_from ? profile.assignment_data.last_date_from : '-'}}</p>
                    <p class="col-form-value">{{profile.assignment_data.date_to ? profile.assignment_data.date_to : '-'}}</p>
                    <p class="col-form-value">{{profile.assignment_data.min_hours_per_week ? profile.assignment_data.min_hours_per_week + ' - ' + profile.assignment_data.max_hours_per_week: '-'}}</p>
         
                </b-col>
            </b-row>
            
            <assignments-profile-plan-data-change v-if="mAssignmentsDataChange.show" :show.sync="mAssignmentsDataChange.show" :result.sync="mAssignmentsDataChange.result" :args.sync="mAssignmentsDataChange.args" :return.sync="profile"/> 
     </div>
</template>
<script>

import AssignmentsProfilePlanDataChange from './AssignmentsProfilePlanDataChange.vue';

export default {
    components: {
        AssignmentsProfilePlanDataChange
    },

    props:["profile"],

    data(){
        return{
            mAssignmentsDataChange: { show: false, args: {
                id_assignments_data_main:null,
                id_assignment_const_status:null,
                date_from: null,
                last_date_from: null,
                date_to: null,
                min_hours_per_week: null,
                max_hours_per_week: null,
                
            }, result: 0, return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,

            
        }
    },

    methods:{       
        change(){
            this.mAssignmentsDataChange.args.id_assignment_data_main = this.profile.id;
            this.mAssignmentsDataChange.args.id_assignment_const_status = this.profile.assignment_data.id_assignment_const_status;
            this.mAssignmentsDataChange.args.date_from = this.profile.assignment_data.date_from;
            this.mAssignmentsDataChange.args.last_date_from = this.profile.assignment_data.last_date_from;
            this.mAssignmentsDataChange.args.date_to = this.profile.assignment_data.date_to;
            this.mAssignmentsDataChange.args.min_hours_per_week = this.profile.assignment_data.min_hours_per_week;
            this.mAssignmentsDataChange.args.max_hours_per_week = this.profile.assignment_data.max_hours_per_week;
            this.mAssignmentsDataChange.show = true;
        },

    }
 
};
</script>
