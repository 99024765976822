<template>
    <div>
        <b-card>
            <app-view-table :isTitle="oneTab" :title="trans('registraion',5)" :loading="loading_list" :filters.sync="filters" :items="cProfile.registration.items" :fields="fields" :customColumns="customColumns" :customColumnsHeaders="customHeaders" >
                <template v-slot:head_selected>
                        <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                            <b-form-group class="pl-2">
                                <b-form-checkbox v-model="selected_all_rows" class="custom-control-primary" @change="selectAll()" style="margin-top:5px" size="md" />
                            </b-form-group>
                        </div>
                    </template>

                    <template v-slot:selected="cell">
                        <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                            <b-form-group class="pl-2">
                                <b-form-checkbox v-model="cell.data.item.selected" :key="refreshCheck" @change="select($event)" class="custom-control-primary" style="margin-top:5px" size="md" />
                            </b-form-group>
                        </div>
                    </template>
                
                <template v-slot:avatar="cell">
                    <app-user-avatar :id="cell.data.item.id_person_data_main" :user="getUser(cell.data.item)" :customClass="cell.data.item.entry_type == 0 ? false : true"/>
                </template>

                <template v-slot:distance="cell">
                    <span v-overflow-tooltip v-if="cell.data.item.distance >= 0">{{cell.data.item.distance ? cell.data.item.distance + ' km' : ''}}</span>
                    <span v-overflow-tooltip v-if="cell.data.item.distance == -1">{{trans('sh-no-flexworker-location',215)}}</span>
                    <span v-overflow-tooltip v-if="cell.data.item.distance == -2">{{trans('as-no-service-location',217)}}</span>
                    <span v-overflow-tooltip v-if="cell.data.item.distance == -3">{{trans('sh-no-location',215)}}</span>
                </template>

                <template v-slot:menu-items="cell">
                    <b-dropdown-item :to="{ name: 'people-person-profile', params: { id_person_data_main: cell.data.item.id_person_data_main }}">{{trans('to-profile',175)}}</b-dropdown-item>
                    <b-dropdown-item v-if="[2, 8].includes(profile.assignment_data.id_assignment_const_status) && [1].includes(cell.data.item.id_applicant_const_status) && (user_role < 3 || profile.can_user_edit)" @click="suggestClient(cell.data.item, false)">{{trans('sh-offer-to-client',220)}}</b-dropdown-item>

                    <b-dropdown-item v-if="cell.data.item.id_assignment_const_status == 2 && cell.data.item.id_applicant_const_status == 2 && (user_role < 3 || profile.can_user_edit)" @click="confirmPerson(cell.data.item.id_assignment_ref_applicants, cell.data.item.id_person_data_main, cell.data.item.person_name_alert)">{{trans('sh-confirmed-by-client',220)}}</b-dropdown-item>
                    <b-dropdown-item v-if="[2, 8].includes(cell.data.item.id_assignment_const_status) && [1, 2].includes(cell.data.item.id_applicant_const_status) && (user_role < 3 || profile.can_user_edit)" @click="deleteRegistration(cell.data.item, false)">{{trans('decline',175)}}</b-dropdown-item>
                </template>

                <template v-slot:buttons>
                    <b-dropdown v-if="selected_rows > 0 && (show_multi_suggest || show_multi_delete)" no-caret toggle-class="app-button-multi mr-4" variant="secondary-grey">
                        <template #button-content>
                            {{trans('action',3)}} ({{selected_rows}})                         
                        </template>                 
                        <b-dropdown-item v-if="show_multi_suggest" @click="suggestClient(null, true)">{{trans('sh-offer-to-client',220)}}</b-dropdown-item>
                        <b-dropdown-item v-if="show_multi_delete" @click="deleteRegistration(null, true)">{{trans('decline',175)}}</b-dropdown-item>      
                    </b-dropdown>
                    
                </template>

            </app-view-table>  
        </b-card> 
    </div>
</template>
<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import AppUserAvatar from '@core/components/AppUserAvatar.vue';

export default {
    components: {
        AppUserAvatar
    },

    props:["profile", "oneTab"],

    mounted() {
      this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }
        }

    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {
                    statuses: { type: 'list', changed: 0, values: [], function: "getTranslationsTypes", parameters: null, widthClass:'app-width-min-250'},
                },
                page: 1,
                per_page: 5,
                search: "",
                changed: 0
            },
            fields: [
                { key: "selected", label: "",tdClass:"p-0", thClass:"p-0", visible: true  },
                { key: "entry_date", label: this.trans('registraion',5), thStyle: {"min-width": "150px", "max-width": "200px"}, visible: true},
                { key: "person_name", label: this.trans('full-name',3), thStyle: { "min-width": "80px",width: "100%" }, visible: true},
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "180px", "max-width": "300px"}, visible: true},
                { key: "applicant_type_name", label: this.trans('agreement',3), thStyle: { "min-width": "130px", "max-width": "200px" }, visible: true},
                { key: "person_office_name", label: this.trans('branch', 182), thStyle: {"min-width": "130px", "max-width": "200px"}, visible: true},
                { key: "assignment_ref_applicant_status", label: this.trans('status',3), thStyle: { "min-width": "130px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "statuses"},
                { key: "distance", label: this.trans('planning-distance',209), thStyle: {"min-width": "100px", "max-width": "200px"}, visible: true},
            ],
            items: [],
            customColumns: ["selected", "distance", "avatar"],
            customHeaders: ['selected'],
            alertClass: new AppAlerts(),
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            selected_rows:0,
            selected_all_rows: false,
            refreshCheck:0,
            show_multi_suggest: false,
            show_multi_delete: false
        };
    },

    methods: {
        getList: function() {
           this.loading_list = true;
           axios
                .post("planning/assignments/getRegistrationList", {
                    id_assignment_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                   .then((res) => {
                    this.cProfile.registration = res.data;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.selected_rows = 0;
                    this.selected_all_rows = 0;
                    this.loading_list = false;
                });
        },

        deleteRegistration: function(data, multi) {

            var ids = [];
            var message = null;

            if(multi){
                ids = this.cProfile.registration.items.list.filter(function(value) {
                                                                                    return value.selected == true; }).map(a => a.id_assignment_ref_applicants);
                message = this.trans('confirm-message',180);
            }else{
                ids = [data.id_assignment_ref_applicants];
                message = this.trans('sh-reject-applicant-confirmation',221, {personNameApplicant : data.person_name_alert});
            }
            
            this.alertClass.openAlertConfirmation(message).then(res => {
                if(res == true){
                    this.loading_list = true;
                    
                    axios
                        .post("planning/assignments/person/reject", {
                            filters: JSON.stringify(this.filters),
                            ids_assignment_ref_applicants: JSON.stringify(ids),
                            id_assignment_data_main: this.profile.id,
                            return_type: 'assignment_profile_registration_list'
                        })
                        .then((res) => {
                            this.cProfile.registration = res.data;
                            this.filters.page = res.data.page;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.cProfile.refresh ++;
                            this.cProfile.history.items.refresh ++;
                            this.selected_rows = 0;
                            this.selected_all_rows = 0;
                            this.loading_list = false;
                        });
                }     
            }); 
        },

        selectAll(){
            if(this.selected_all_rows){
                this.cProfile.registration.items.list.forEach(element => {
                    element.selected = true;
                });

                this.selected_rows = this.cProfile.registration.items.list.length;

            } else {
                this.cProfile.registration.items.list.forEach(element => {
                    element.selected = false;
                });
                this.selected_rows = 0;
            }
            
            this.multiButtonVisible();
            this.refreshCheck++;
        },

        select(){
            if (this.cProfile.registration.items != undefined && this.cProfile.registration.items.list != undefined) {
                let selected_all = this.cProfile.registration.items.list.filter(function(value) { 
                    return value.selected == true; 
                }).length;

                if(this.cProfile.registration.items.list.length == selected_all){
                    this.selected_all_rows = true;
                }else{
                    this.selected_all_rows = false;
                }

                this.selected_rows = selected_all;
            }else{
                this.selected_rows = selected_all;
            }

            this.multiButtonVisible();
        },

        multiButtonVisible(){
            
            if(this.selected_rows > 0 && this.cProfile.registration.items != undefined && this.cProfile.registration.items.list != undefined){
                var userRole = this.user_role;

                let sugguest_person = this.cProfile.registration.items.list.filter(function (value){
                    return value.selected == true && ( userRole < 3 || value.can_user_edit) && [8,2].includes(value.id_assignment_const_status) && ![2, 4, 9].includes(value.id_applicant_const_status)
                }).length;

                let delete_person = this.cProfile.registration.items.list.filter(function (value) {
                    return value.selected == true && ( userRole < 3 || value.can_user_edit) && [2, 8].includes(value.id_assignment_const_status) && [1, 2].includes(value.id_applicant_const_status)
                }).length;


                if(sugguest_person == this.selected_rows){
                    this.show_multi_suggest = true;
                }else{
                    this.show_multi_suggest = false;
                }

                if(delete_person == this.selected_rows){
                    this.show_multi_delete = true;
                }else{
                    this.show_multi_delete = false;
                }

            }

        },

        getUser(item){
            if(item.entry_type == 0){
                return {
                    shortcut_name: item.user_shortcut_name,
                    first_name: item.user_name,
                };
            }else if(item.entry_type == 1){
                return {
                    shortcut_name: 'P',
                    first_name: 'Portal HappyNurse',
                };
            }
        },

        suggestClient(data, multi){

            let show_document_alert = false;
            let person_names = "";
            let ids = [];

            if(multi){
                ids = this.cProfile.registration.items.list.filter(function (value) {
                                                                                    return value.selected == true; }).map(a => a.id_assignment_ref_applicants);
                
                let persons = this.cProfile.registration.items.list.filter(function (value) {
                                                                            return value.selected == true && value.applicant_type == 1 && (value.id_document_data_main == null || value.id_document_data_main == undefined)
                                                                        }).map(a => a.person_name_alert);
                
                if(persons.length > 0){
                    show_document_alert = true;

                    persons.forEach(item => {
                        person_names += item + ', '
                    });

                    person_names = person_names.slice(0, -2);
                }
                    
            }else{
                ids = [data.id_assignment_ref_applicants];
                
                if(data.applicant_type == 1 && (data.id_document_data_main == null || data.id_document_data_main == undefined)){
                    show_document_alert = true;
                    person_names = data.person_name_alert;
                }
            }
            
            if(show_document_alert){
                this.alertClass.openAlertWarning(this.trans('as-flexworker-not-have-cv',216,{person_names : person_names})).then(res => {})
            }else{

                var message = this.trans('as-send-secondemt-to-organisation-confirmation',216);
                this.alertClass.openAlertConfirmation(message).then(res => {

                    if(res == true){
                        this.loading_list = true;

                        axios
                            .post('planning/assignments/person/suggestToClient', {
                                ids_assignment_ref_applicants: JSON.stringify(ids),
                                id_assignment_data_main: this.profile.id,
                                filters: JSON.stringify(this.filters),
                                return_type: 'assignment_profile_registration_list'
                            })
                            .then((res) => {
                                this.cProfile.registration = res.data;
                                this.filters.page = res.data.page;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            })
                            .finally(() => {
                                this.selected_rows = 0;
                                this.selected_all_rows = 0;
                                this.cProfile.refresh ++;
                                this.cProfile.history.items.refresh ++;
                                this.loading_list = false;
                            })

                    }
                }); 

            }
                  
        },


        confirmPerson(idAssignmentRefApplicants, idPersonDataMain, personName){
            var message = this.trans('as-person-for-secondment-confirmation',216,{personName : personName}); 

            this.alertClass.openAlertConfirmation(message).then(res => {

                if(res == true){
                    this.loading_list = true;
                    

                    axios
                        .post('planning/assignments/person/confirm', {
                            id_assignment_data_main: this.profile.id,
                            id_assignment_ref_applicants: idAssignmentRefApplicants,
                            id_person_data_main: idPersonDataMain,
                            filters: JSON.stringify(this.filters),
                            return_type: 'assignment_profile_registration_list'
                        })
                        .then((res) => {
                            this.cProfile.registration = res.data;
                            this.filters.page = res.data.page;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.selected_rows = 0;
                            this.selected_all_rows = 0;
                            this.cProfile.refresh ++;
                            this.cProfile.history.items.refresh ++;
                            this.loading_list = false;
                        });

                }

            });      
        },

        onCreateThis() { 

        
            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('profile.registration.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
        },

    },

    

 
};
</script>