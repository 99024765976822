<template>
    <b-modal ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('cp-add-department',192)">
         <ValidationObserver ref="form" >

             <app-row-left-label :label="trans('date-from',182)">
                <app-date v-model="date_from" :validatorName="trans('date-from',182)" :validatorRules="'required|date-check|date-more-than:'+cArgs.assignment_date_from + '|disable-date-future-by-date:'+ cArgs.assignment_date_to" :validatorCustomMessage="{ 'date-more-check': trans('planning-start-date-in-past-error',211) }" :disabledDatesRange="disabledDatesFromRange"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('date-to',182)">
                <app-date v-model="date_to" :validatorName="trans('date-to',182)" :validatorRules="'required|date-check|date-more-than:'+date_from + '|disable-date-future-by-date:'+ cArgs.assignment_date_to" :disabledDatesRange="disabledDatesToRange"/>
            </app-row-left-label>

             <app-row-left-label :label="trans('departments',3)" >
                <app-select v-model="department_data" type="getDepartmentsFromAssignment" :validatorName="trans('departments',3)" validatorRules="required" :args.sync="users_args"/>
             </app-row-left-label>

        </ValidationObserver>

        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="add" :loading="loading">
                {{trans('add',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import moment from "moment";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return", "filters"],

    created(){
        this.date_from = this.cArgs.assignment_date_from;
        this.date_to = this.cArgs.assignment_date_to;
    },
    
    mounted() {
        this.users_args.id_assignment_data_main = this.cArgs.id_assignment_data_main;
        this.users_args.id_location_data_main = this.cArgs.id_location_data_main;

        this.disabledDatesFromRange = '(date <= new Date('+moment(this.cArgs.assignment_date_from,"DD-MM-YYYY").subtract(1, 'd')+')) || (date >= new Date('+moment(this.cArgs.assignment_date_to,"DD-MM-YYYY").add(1, 'd')+'))';
        this.disabledDatesToRange = '(date <= new Date('+moment(this.cArgs.assignment_date_from,"DD-MM-YYYY").subtract(1, 'd')+')) || (date >= new Date('+moment(this.cArgs.assignment_date_to,"DD-MM-YYYY").add(1, 'd')+'))';
    },

    watch: {
      "date_from": {
            handler: function(val) {
                if(moment(this.date_from,"DD-MM-YYYY") >= moment().subtract(1, 'd')){
                    this.disabledDatesToRange = '(date <= new Date('+moment(this.date_from,"DD-MM-YYYY").subtract(1, 'd')+')) || (date >= new Date('+moment(this.cArgs.assignment_date_to,"DD-MM-YYYY").add(1, 'd')+'))';
                }         
            },
        },
    },

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
        get() { return this.args },
        set() { this.$emit('update:args', false) }
        }
    },

    data() {
        return {
            department_data: null,
            date_from: null,
            date_to: null,
            users_args:{
                id_assignment_data_main: null,
                id_location_data_main: null,
            },
            loading: false,
            disabledDatesToRange: null,
            disabledDatesFromRange: null,
        };
    },

    methods: {
        add() {
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("planning/assignments/addDepartment", {
                            id_assignment_data_main: this.args.id_assignment_data_main,
                            id_department_data_main: this.department_data.value,
                            date_from: this.date_from,
                            date_to: this.date_to,
                            filters: JSON.stringify(this.args.filters),
                        })
                       .then((res) => {
                            this.$emit("update:return", res.data.items);
                            this.$emit("update:show", false);
                            this.loading = false;
                        })
                        .catch((error) => {

                            console.error(`error during request: ${error}`);
                        });
                        }
            })
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped></style>