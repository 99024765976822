<template>
    <div>
        <b-card>
            <app-view-table :isTitle="false" :loading="loading_list" :filters.sync="filters" :items="cProfile.shifts.items" :fields="fields">

                <template v-slot:menu-items="cell">
                    <b-dropdown-item :to="{ name: 'planning-shifts-profile', params: { id_shift_data_main: cell.data.item.id_shift_data_main }}">{{trans('open-job-offer',218)}}</b-dropdown-item>    
                </template>

            </app-view-table>  
        </b-card> 
    </div>
</template>
<script>

import axios from "@axios";

export default {
    components: {
    },

    props:["profile"],

    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {                        
                    dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'},                   
                    shift_statuses: { type: 'list', changed: 0, values: [], function: "getShiftStatuses", parameters: null, widthClass:'app-width-min-350'},
                    daytime_name: { type: 'list', changed: 0, values: [], function: "getShiftDaytime", parameters: null, widthClass:'app-width-min-350'},
                },
                page: 1,
                per_page: 5,
                search: "",
                changed: 0
            },
            fields: [
                { key: "date", label: this.trans('date',182), thStyle: { "min-width": "100px", width:"100%" }, visible: true, filtering: true, filtering_name: "dates"},
                { key: "hour_from", label: this.trans('planning-begin',209), thStyle: { "min-width": "100px", "max-width": "150px" }, visible: true, filtering: false},
                { key: "hour_to", label: this.trans('planning-end',209), thStyle: { "min-width": "100px", "max-width": "150px" }, visible: true, filtering: false},
                { key: "daytime_name", label: this.trans('planning-service-type',209), thStyle: { "min-width": "175px", "max-width": "300px" }, visible: true, filtering: true, filtering_name: "daytime_name"},
                { key: "status_name", label: this.trans('status',3), thStyle: { "min-width": "175px", "max-width": "300px" }, visible: true, filtering: true, filtering_name: "shift_statuses"},

            ],
            items:[],
        };
    },

    methods: {
        
        getList: function() {
            this.loading_list = true;
            axios
                .post("planning/assignments/getShiftsList", {
                    id_assignment_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                   .then((res) => {
                    this.cProfile.shifts = res.data;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

        onCreateThis() { 

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('profile.shifts.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.filtering.daytime_name.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });  

            this.$watch('filters.filtering.shift_statuses.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.dates.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });  
        },

    },

    

 
};
</script>