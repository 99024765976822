<template>
    <b-modal ref="modal-change" v-model="computedShow" no-close-on-backdrop :title="trans('as-change-plan-details',212)">
        
        <ValidationObserver ref="form" >

            <app-row-left-label :label="trans('as-desired-start-date',217)">
                <app-date v-model="cArgs.date_from" validatorName="Gewenste startdatum" :validatorRules="args.id_assignment_const_status == 4 ? '' : 'required|date-check|date-more-check'" :validatorCustomMessage="{ 'date-more-check': trans('planning-start-date-in-past-error',211) }" :disabled="(args.id_assignment_const_status == 4)" :disabledDatesPass="1"/>
            </app-row-left-label>
            <app-row-left-label :label="trans('planning-last-start-date',209)">
                <app-date v-model="cArgs.last_date_from" validatorName="Laatste startdatum" :validatorRules="args.id_assignment_const_status == 4 ? '' : ('required|date-check|date-more-check|date-more-than:'+cArgs.date_from)" :validatorCustomMessage="{ 'date-more-check': trans('planning-last-start-date-in-past-error',211) }" :disabled="(args.id_assignment_const_status == 4)" :disabledDatesPass="cDisabledDatesLast"/>
            </app-row-left-label>
            <app-row-left-label :label="trans('date-to',182)">
                <app-date v-model="cArgs.date_to" validatorName="Einddatum" :validatorRules="'required|date-check|date-more-than:'+cArgs.last_date_from" :disabledDatesPass="cDisabledDatesEnd"/>
            </app-row-left-label>
            <app-row-left-label :slots="[6, 6]" :label="trans('planning-number-of-hours-per-week',209)">
                <template v-slot:col-1>
                    <app-input v-model="cArgs.min_hours_per_week" validatorName="Soort opdracht" validatorRules="required|weekcheck" type="integer_02" />
                </template>
                <template v-slot:col-2>
                    <app-input v-model="cArgs.max_hours_per_week" validatorName="Soort opdracht" :validatorRules="'required|weekcheck|number-more-than:'+cArgs.min_hours_per_week" type="integer_02" />
                </template>
            </app-row-left-label>
        
        </ValidationObserver> 

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>
<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import { datecheck, weekcheck } from "@validations";
import moment from "moment";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "args", "result", "return"],

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cDisabledDatesLast: {
            get() {
                var today = moment(moment.now());
                var d = today.diff(moment(this.cArgs.date_from, 'DD-MM-YYYY'), 'days');
                return today.format('DD-MM-YYYY') == this.cArgs.date_from ? 1 : d;
            }
        },

        cDisabledDatesEnd: {
            get() {
                var today = moment(moment.now());
                var d = today.diff(moment(this.cArgs.last_date_from, 'DD-MM-YYYY'), 'days');
                return d == 0 ? 1 : d;
            }
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {

        change() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }
               else{
                     this.loading = true;
                axios
                    .post("planning/assignments/planDataChange", {
                        id_assignment_data_main: this.args.id_assignment_data_main,
                        assignment_data: JSON.stringify(this.args),
                    })
                    .then((res) => {
                        this.$emit("update:show", false);
                        this.$emit("update:result", 1);
                        this.cReturn.assignment_data = res.data;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    });
                }
            })
                
        },

        closeModal() {
            this.computedShow = false;
        },
    }
    
}
</script>
<style>

</style>