<template>
    <div v-if="profile !== null" class="app-navbar-profile-content">
        <b-navbar sticky class="app-navbar-profile">
            <div class="app-navbar-header">
                <b-row>
                    <b-col cols="8" class="app-navbar-profile-margin-4"></b-col>
                    <b-col end class="app-navbar-profile-margin-30">
                        <div class="d-flex justify-content-end align-items-center">
                          <div class="font-weight-800 mr-4 mt-1"> {{trans('core-assignment-profile', 271)}} </div>
                            <b-dropdown variant="link" right>
                                <template #button-content>
                                    <b-icon icon="gear-fill" class="text-secondary" style="width: 17px; height: 17px;"></b-icon>
                                </template>
                            </b-dropdown>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="align-items-center">
                    <b-col> 
                        <assignments-profile-status-bar :profile.sync ="profile" />
                    </b-col>        
                </b-row> 
            </div>
        </b-navbar>

        <div>
            <b-row>
              <b-col md="12" lg="8" class="pr-lg-2">
                    <assignments-profile-action-points-component :profile.sync="profile" />
                    <assignments-profile-informations-component class="mb-3" :profile.sync ="profile" />

                    <!-- TABS -->
                    <b-tabs ref="tabs" class="mb-3" justified :nav-class="cOneTab ? 'd-none' : ''">
                        <b-tab :title="trans('registraion',5)" @click="refreshTab(0)">
                            <assignments-profile-registration-component :profile.sync ="profile" :oneTab="cOneTab"/>
                        </b-tab>  
                        <b-tab :title="trans('services',3)" @click="refreshTab(1)" v-if="profile.assignment_data.id_assignment_const_status > 1">
                            <assignments-profile-shifts-component :profile.sync ="profile"/>
                        </b-tab>  
                    </b-tabs>

                    <!--Collapses -->
                    <app-collapse accordion type="margin">
                        <b-card no-body class="app-card-profile" >
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('planning-plan-data',173)" :id="'assignment-plan'">
                              <assignments-profile-plan-data-component :profile.sync ="profile" />
                          </app-collapse-item>
                        </b-card>
                        <b-card no-body class="app-card-profile" >
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('documents',3)" :id="'documents'">
                              <assignments-profile-documents-component :profile.sync ="profile" />
                          </app-collapse-item>
                      </b-card>

                      <b-card no-body class="app-card-profile" >
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('sh-activity-log',215)" :id="'assignment-history'">
                              <assignments-profile-history-component :profile.sync="profile" />
                          </app-collapse-item>
                      </b-card>
                    </app-collapse>

                </b-col>

                <b-col md="12" lg="4" class="mt-3 mt-lg-0 pl-lg-2">

                    <assignments-profile-flexworker-component v-if="profile.assignment_data.id_person_data_main != null && profile.assignment_data.id_assignment_const_status > 1" class="mb-3" :profile.sync ="profile" />
                    <assignments-profile-additional-functions-component :profile.sync="profile" />   
                    <assignments-profile-departments-component :profile.sync="profile"/>
                </b-col>
            </b-row>    
        </div>

    </div>
</template>

<script>

import axios from "@axios";
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";

import AssignmentsProfileActionPointsComponent from './ActionPoints/AssignmentsProfileActionPointsComponent.vue';
import AssignmentsProfileStatusBar from './StatusBar/AssignmentsProfileStatusBar.vue';
import AssignmentsProfileAdditionalFunctionsComponent from './AdditionalFunctions/AssignmentsProfileAdditionalFunctionsComponent.vue';
import AssignmentsProfileInformationsComponent from './Informations/AssignmentsProfileInformationsComponent.vue';
import AssignmentsProfileFlexworkerComponent from './Flexworker/AssignmentsProfileFlexworkerComponent.vue';

//tabs
import AssignmentsProfileRegistrationComponent from './Registration/AssignmentsProfileRegistrationComponent.vue';
import AssignmentsProfileShiftsComponent from './Shifts/AssignmentsProfileShiftsComponent.vue';

//accordions
import AssignmentsProfilePlanDataComponent from './PlanData/AssignmentsProfilePlanDataComponent.vue';
import AssignmentsProfileDocumentsComponent from './Documents/AssignmentsProfileDocumentsComponent.vue';
import AssignmentsProfileHistoryComponent from './History/AssignmentsProfileHistoryComponent.vue';

import AssignmentsProfileDepartmentsComponent from './Departments/AssignmentsProfileDepartmentsComponent.vue';

export default {
  components: {
    AppCollapse,
    AppCollapseItem,

    AssignmentsProfileActionPointsComponent,
    AssignmentsProfileStatusBar,
    AssignmentsProfileFlexworkerComponent,

    AssignmentsProfileInformationsComponent,
    AssignmentsProfileAdditionalFunctionsComponent,

    AssignmentsProfileShiftsComponent,
    AssignmentsProfileRegistrationComponent,
    AssignmentsProfileDocumentsComponent,
    AssignmentsProfileHistoryComponent,

    AssignmentsProfilePlanDataComponent,

    AssignmentsProfileDepartmentsComponent,
  },

  created() {
    this.getData();
  },

  mounted() {},

  computed : {
    cOneTab: {
      get() {
        if([1].includes(this.profile.assignment_data.id_assignment_const_status)) { 
          return true;
        } else {
          return false;
        }
      }
    }

  },

  watch: {
      "profile.refresh": {
            handler: function(val) {
                if (val > 0) {
                    this.getAssignmentData();
                }                
            },
        },

  },

  data(){
      return {
      
              id_assignment_data_main: this.$route.params.id_assignment_data_main,
              profile: null,
              active_tab: 0,
          }

  },

  methods: {

    getData(){
      axios
        .get("planning/assignments/getProfile/" + this.id_assignment_data_main)
        .then((res) => {
            this.profile = res.data;
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    getAssignmentData(){
      
      axios
        .get("planning/assignments/getAssignmentData/" + this.id_assignment_data_main)
        .then((res) => {
            if(res.data != null){
              this.profile.assignment_data = res.data;  
            }            
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    refreshTab(e){
      if (this.activeTab !== e) {
        this.activeTab = e;
      
        if(e == 0){//Registration
          this.profile.registration.items.refresh ++;
        }else if(e == 1){//Shifts
          this.profile.shifts.items.refresh ++;
        }/*else if(e == 2){
            this.profile.offers.items.refresh ++;
        }*/

      }

    },

    resizeEvent(e){
        
    }

  },
 
};

</script>



