<template>
    <div v-if="showActionPoints">
        
       

        <b-card class="mb-3" v-if="profile.assignment_data.id_assignment_const_status == 1"> <!-- OPEN -->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('action',3)}}</span>  <br/>  
                            <span class="text-small">{{trans('cp-select-flex-worker',198)}}</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="addPerson()" :disabled="cancel_loading || confirm_assignment_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('cp-register-flexworker',189)}} </app-button>
                        <app-button type="secondary-grey" @click="cancelAssignment()" :loading="cancel_loading" :disabled="reject_loading || confirm_person_loading || confirm_assignment_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('cancel-posting',175)}} </app-button>
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <b-card class="mb-3" v-if="profile.assignment_data.id_assignment_const_status == 2"> <!-- watch op klant -->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('core-client',3)}}</span>  <br/>  
                            <span class="text-small" v-if="cRegistrationToConfirm.length == 1">{{trans('as-confirm-employee',212, {person_name: cRegistrationToConfirm[0].person_name_alert})}}</span>
                            <span class="text-small" v-if="cRegistrationToConfirm.length > 1">{{trans('as-confirm-flexworker-for-secondment',218)}}</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary"  @click="confirmPerson(cRegistrationToConfirm[0].id_assignment_ref_applicants, cRegistrationToConfirm[0].id_person_data_main, cRegistrationToConfirm[0].person_name_alert)" :loading="confirm_person_loading" :disabled="reject_loading || cancel_loading || confirm_assignment_loading" v-if="cRegistrationToConfirm.length == 1 && (userRole < 3 || profile.can_user_edit)">{{trans('sh-confirmed-by-client',220)}}</app-button>
                        <app-button type="primary" @click="rejectPerson(cRegistrationToConfirm[0].id_assignment_ref_applicants, cRegistrationToConfirm[0].person_name_alert)"  :loading="reject_loading" :disabled=" cancel_loading || confirm_person_loading || confirm_assignment_loading"  v-if="cRegistrationToConfirm.length == 1 && (userRole < 3 || profile.can_user_edit)"> {{trans('sh-rejected-by-client',220)}} </app-button>
                        
                        <app-button type="secondary-grey" @click="cancelAssignment()" :loading="cancel_loading" :disabled=" reject_loading || confirm_person_loading || confirm_assignment_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('cancel-posting',175)}} </app-button>
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <b-card class="mb-3" v-if="profile.assignment_data.id_assignment_const_status == 3"> <!-- Kennismaking -->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('action',3)}}</span>  <br/>                                 
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary"  @click="confirmAssignment()" :loading="confirm_assignment_loading" :disabled="reject_loading || cancel_loading" v-if="(userRole < 3 || profile.can_user_edit)">{{trans('as-confirm-secondmend',218)}}</app-button>
                        <app-button type="secondary-grey" @click="cancelAssignment()" :loading="cancel_loading" :disabled=" reject_loading || confirm_person_loading || confirm_assignment_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('cancel-posting',175)}} </app-button>
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <b-card class="mb-3" v-if="profile.assignment_data.id_assignment_const_status == 4"><!-- Actief-->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('active-status',3)}}</span>  <br/>  
                            <span class="text-small">{{trans('as-secondment-is-active',218)}}</span>                                     
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="secondary-grey" @click="cancelAssignment()" :loading="cancel_loading" :disabled="reject_loading || confirm_person_loading || confirm_assignment_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('cancel-posting',175)}} </app-button>
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <b-card class="mb-3" v-if="profile.assignment_data.id_assignment_const_status == 8"><!-- Angemeld-->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('active-status',3)}}</span>  <br/>  
                            <span class="text-small">{{trans('cp-select-flex-worker',198)}}</span>                                 
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="addPerson()" :disabled="reject_loading || cancel_loading || confirm_person_loading || confirm_assignment_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('cp-register-flexworker',189)}} </app-button>
                        <app-button type="secondary-grey" @click="cancelAssignment()" :loading="cancel_loading" :disabled="reject_loading || confirm_person_loading || confirm_assignment_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('cancel-posting',175)}} </app-button>
                    </div>
                </div>
            </b-alert>           
        </b-card>

    </div>
</template>
<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {        

    },

    props:["profile"],

    created(){
        this.userRole = JSON.parse(localStorage.getItem('user')).id_user_const_role;
    },
    
    watch: {
   
    },

    computed: {
        showActionPoints () {
            if ([1, 2, 3, 4, 5, 6, 8].includes(this.profile.assignment_data.id_assignment_const_status)) {
                return true;
            } else {
                return false;
            }
        },

        cRegistrationToConfirm:{
            get(){
                return this.profile.registration.items.list_all.filter(function (value) {
                    return value.id_assignment_const_status == 2 && value.id_applicant_const_status == 2
                });
            }
        },

        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }
        } 
    
    },

    data() {
        return {
            alert_class : new AppAlerts(),
            reject_loading: false,
            cancel_loading: false,
            confirm_person_loading: false,
            confirm_assignment_loading: false
        };
    },

    methods: {
        addPerson(){
            let ids_assignment_data_main = [this.profile.id];
                
            this.$router.push({ name: "planning-assignments-add-person", params: { ids_assignment_data_main: ids_assignment_data_main, 
                                                                                   start_date: this.profile.assignment_data.date_from,
                                                                                   returnType: "assignment_card",
                                                                                   returnIdType: this.profile.id }});
        },

        cancelAssignment(){

           this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => { 

                if(res == true){

                    this.cancel_loading = true;

                    let post_data = {
                        ids_assignment_data_main: JSON.stringify([this.profile.id]),
                        return_type: "assignment_profile"
                    };

                    axios
                        .post("planning/assignments/cancel", post_data)
                        .then((res) => {                 
                            this.cProfile.assignment_data = res.data;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.cProfile.history.items.refresh ++;
                            this.cancel_loading = false;
                        });
                }

             });

        },

        confirmPerson(idAssignmentRefApplicants, idPersonDataMain, personName){

            var message = this.trans('as-person-for-secondment-confirmation',216,{personName : personName}); 

            this.alert_class.openAlertConfirmation(message).then(res => {

                if(res == true){

                    this.confirm_person_loading = true;

                    axios
                        .post('planning/assignments/person/confirm', {
                            id_assignment_data_main: this.profile.id,
                            id_assignment_ref_applicants: idAssignmentRefApplicants,
                            id_person_data_main: idPersonDataMain,
                            return_type: "assignment_profile"
                        })
                        .then((res) => {
                            this.cProfile.assignment_data = res.data;
                            this.cProfile.registration.items.refresh ++;
                            this.cProfile.history.items.refresh ++;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.confirm_person_loading = false;
                        });

                }

             });      
        },

        rejectPerson(idAssignmentRefApplicants, personName){

            this.alert_class.openAlertConfirmation(this.trans('sh-reject-applicant-confirmation',221, {personNameApplicant : personName})).then(res => {
                if(res == true){
                    this.reject_loading = true;

                    axios
                        .post('planning/assignments/person/reject', {
                            id_assignment_data_main: this.profile.id,
                            ids_assignment_ref_applicants: JSON.stringify([idAssignmentRefApplicants]),
                            return_type: 'assignment_profile'
                        })
                        .then((res) => {
                            this.cProfile.assignment_data = res.data;
                            this.cProfile.registration.items.refresh ++;
                            this.cProfile.history.items.refresh ++;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.reject_loading = false;
                        })

                }
            });       
        },

        confirmAssignment(){
            
            this.alert_class.openAlertConfirmation(this.trans('as-secondment-confirmation',216)).then(res => {
                if(res == true){
                    this.confirm_assignment_loading = true;

                    axios
                        .post('planning/assignments/confirm', {
                            ids_assignment_data_main: JSON.stringify([this.profile.id]),
                            return_type: 'assignment_profile'
                        })
                        .then((res) => {
                            this.cProfile.assignment_data = res.data;
                            this.cProfile.registration.items.refresh ++;
                            this.cProfile.history.items.refresh ++;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.confirm_assignment_loading = false;
                        })

                }
            }); 

        }
    }
 
};
</script>

<style>
</style>