<template>
    <div>
        <b-badge  :variant="getVariant"  pill> 
            {{getText}}
        </b-badge>
    </div>
</template>
<script>
export default {
    
    props: {
        type: {type: Number},
    },

    computed: {
        getText:function(){
            if(this.type == 1){
                return this.trans('fp-user',172);
            }else if(this.type == 2){
                return this.trans('core-client',3);
            }else if(this.type == 3){
                return this.trans('flexworker',182);
            }else{
                return '';
            }
        },

        getVariant:function(){
            if(this.type == 1){
                return 'neutral';
            }else if(this.type == 2){
                return 'succes';
            }else if(this.type == 3){
                return 'pending';
            }else{
                return 'neutral';
            }
        }

    },


};
</script>

