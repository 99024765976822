<template>
    <div>
        <b-card class="mt-3">
            <app-view-table :title="trans('departments',3)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="this.cProfile.departments.items" :fields="fields" bottomBarStaticHeight :isDropdownMenu="false">
                
                <!--
                    :isDropdownMenu="this.cProfile.departments.items.total > 1 && (user_role < 3 || profile.can_user_edit)" //w przypadku przywrocenia 3 kropek
                    <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="deleteDepartment(cell.data.item)">{{trans('cp-delete-department',212)}}</b-dropdown-item>
                </template>-->

                <template #buttons v-if="user_role < 3 || profile.can_user_edit">
                    <app-button type="primary" @click="addDepartment">{{trans('cp-add-department',192)}}</app-button>
                </template>

            </app-view-table>

        </b-card>  
        <assignments-profile-departments-add v-if="mAddDepartment.show" :show.sync="mAddDepartment.show" :result.sync="mAddDepartment.result" :args="mAddDepartment.args" :filters.sync="filters" :return.sync="cProfile.departments.items"/>
    </div>
</template>
<script>

import axios from "@axios";
import AssignmentsProfileDepartmentsAdd from "./AssignmentsProfileDepartmentsAdd.vue";
import AppAlerts from '@core/scripts/AppAlerts';

export default {

    components: {
        AssignmentsProfileDepartmentsAdd,
    },

    props: ['profile'],
    
    mounted() {
        this.onCreateThis();
    },

    created() {
        this.user_role = JSON.parse(localStorage.getItem('user')).id_user_const_role;
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', this.profile) }
        }
    },

    data() {
        return {
            mAddDepartment: { show: false, args: { id_assignment_data_main: null,
                                                    id_location_data_main: null,
                                                    filters: null,
                                                    assignment_date_from: null,
                                                    assignment_date_to: null
                            }, result: 0, return: null },
            loading_list: false,
            filters: {
                page: 1,
                per_page: 10,
                 sorting: {
                    field_name: null,
                    sort_direction: 0,
                    changed: 0,
                },
                filtering: {},
                search: "",
                status: 1,
                changed: 0,
            },
            fields: [
                { key: "name", label: this.trans('full-name',3), thStyle: { "min-width": "100px", width:"100%" }, visible: true, filtering: false },     
                { key: "date_from", label: this.trans('date-from',182), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: false },
                { key: "date_to", label: this.trans('date-to',182), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: false },                           
            ],
            alertClass: new AppAlerts(),
            user_role: null
        };
    },


    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("planning/assignments/getDepartmentsList", {
                    id_assignment_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.cProfile.departments.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

         deleteDepartment(data) {
                this.alertClass.openAlertConfirmation(this.trans('confirm-message', 180)).then(res => {
                    if(res == true){
                        this.loading_list = true;
                        axios
                            .post("planning/assignments/deleteDepartment", {
                                 filters: JSON.stringify(this.filters),
                                 id_assignment_ref_department: data.id_assignment_ref_department,
                                 id_assignment_data_main: this.profile.id,
                           })
                            .then((res) => {
                                this.filters.page = res.data.page;
                                this.cProfile.departments.items = res.data.items;
                             })
                             .catch((error) => {
                                 console.error(`error during request: ${error}`);
                            }).finally(() => {
                                 this.loading_list = false;
                             });;
                     }     
                 }); 
         },

        addDepartment(item){
            this.mAddDepartment.args.id_assignment_data_main = this.profile.id;
            this.mAddDepartment.args.id_location_data_main = this.profile.assignment_data.id_location_data_main;
            this.mAddDepartment.args.filters = this.filters;
            this.mAddDepartment.args.assignment_date_from = this.profile.assignment_data.date_from;
            this.mAddDepartment.args.assignment_date_to = this.profile.assignment_data.date_to;
            this.mAddDepartment.show = true;
        },

        onCreateThis() { 

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('cProfile.departments.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        }
    },
 
};
</script>
<style>
</style>